var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "container w-auto -mx-2 sm:mx-4 text-left" },
    [
      _c("div", { staticClass: "container py-0 mx-auto" }, [
        _c("h1", { staticClass: "mb-5 text-xl text-black" }, [
          _vm._v(" " + _vm._s(_vm.$t("edit_profile.your_register")) + " "),
        ]),
        _c(
          "form",
          {
            attrs: { method: "post" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.saveForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "mb-5 lg:flex lg:space-x-5" }, [
              _c("div", { staticClass: "box-item" }, [
                _c("h2", [_vm._v(_vm._s(_vm.$t("edit_profile.owner_data")))]),
                _c(
                  "div",
                  { staticClass: "box-form" },
                  [_c("FormPerson", { on: { personData: _vm.updatePerson } })],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex flex-wrap" },
                  [
                    _vm.isArgentina
                      ? _c("FormulateInput", {
                          attrs: {
                            type: "select",
                            name: "documentType",
                            label: _vm.$t("form.document_type"),
                            options: _vm.tipoDocList,
                            "outer-class": ["w-full sm:w-1/2 sm:pl-1 md:w-2/2"],
                            "error-class": ["text-red-600"],
                            "data-cy": "profile_form-document-type",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.eventGtm("custom.editDocumentType")
                            },
                          },
                          model: {
                            value: _vm.getProfile.person.doc_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.getProfile.person, "doc_type", $$v)
                            },
                            expression: "getProfile.person.doc_type",
                          },
                        })
                      : _vm._e(),
                    _c("FormulateInput", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: _vm.mask,
                          expression: "mask",
                        },
                      ],
                      attrs: {
                        type: "text",
                        label: _vm.$t("form.document_number"),
                        name: "document",
                        icon: "id-card",
                        inputmode: "text",
                        validation: _vm.documentValidation,
                        "validation-messages": {
                          required: _vm.$t("form.required_field"),
                          min: _vm.$t("form.input_invalid"),
                        },
                        "error-class": ["text-red-600"],
                        "outer-class": [
                          "w-full sm:w-1/2 sm:pr-1 md:w-2/2 md:pl-1",
                        ],
                        "data-cy": "profile_form-document-number",
                      },
                      on: {
                        validation: function ($event) {
                          _vm.validation = $event
                        },
                        click: function ($event) {
                          return _vm.eventGtm("custom.editDocument")
                        },
                      },
                      model: {
                        value: _vm.getProfile.person.cpf_cnpj,
                        callback: function ($$v) {
                          _vm.$set(_vm.getProfile.person, "cpf_cnpj", $$v)
                        },
                        expression: "getProfile.person.cpf_cnpj",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "box-form" },
                  [_c("FormPhone", { on: { phoneData: _vm.updatePhones } })],
                  1
                ),
              ]),
              _c("div", { staticClass: "box-item" }, [
                _c("h2", [_vm._v(_vm._s(_vm.$t("edit_profile.profile_data")))]),
                _c(
                  "div",
                  { staticClass: "box-form" },
                  [_c("FormStore", { on: { storeData: _vm.updateStore } })],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "mb-5 box-item" }, [
              _c("h2", [
                _vm._v(_vm._s(_vm.$t("edit_profile.billing_address"))),
              ]),
              _c(
                "div",
                { staticClass: "box-form" },
                [
                  _c("FormAddress", {
                    attrs: { "address-type": "billingAddress" },
                    on: { addressData: _vm.updateBillingAddress },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "text-right" }, [
              _c(
                "button",
                { staticClass: "btn-cta-blue", attrs: { type: "submit" } },
                [_vm._v(" " + _vm._s(_vm.$t("edit_profile.save")) + " ")]
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }