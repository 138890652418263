export default class DocumentFormats {
  static DOCUMENTS = {
    EG: "###########",     // Egito: Número de Identificação Nacional (11 dígitos)
    US: "###-##-####",     // Estados Unidos: Social Security Number (SSN, 9 dígitos, formato ###-##-####)
    ES: "########N"        // Espanha: Documento Nacional de Identidad (DNI, 8 dígitos seguidos de 1 letra)
  }

  static getFormatByCountry(country) {
    return this.DOCUMENTS[country] || "###.###.###-##";
  }
}
