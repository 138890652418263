export default class PostalCodeFormat {
  static CODE_FORMATS = {
    AR: "####",
    EG: "#####",
    US: "#####-####",
    ES: "#####"
  };

  static getFormatByCountry(country) {
    return this.CODE_FORMATS[country] || "#####-###";
  }
}
