<template>
  <div class="m-0 p-0">
    <div class="home">
      <message-welcome />
      <user-data />
    </div>
    <div
      class="text-sm text-gray-400 hover:underline text-right w-full"
      data-cy="dashboard-cancel-div"
    >
      <router-link
        v-if="!isNotSubscriber"
        :to="{ name: routeCancel }"
        @click.native="eventGtm('custom.linkCancelPlan')"
      >
        {{ $t("components.home.cancelPlan") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import MessageWelcome from "@/components/Home/MessageWelcome.vue";
import UserData from "@/components/Home/UserData.vue";
import { CANCEL_PLAN_VIEW } from "@/router/constants";
import { CHANGE_LANGUAGE } from "@/store/constants/login";
import { GET_STORE, STORE, STORE_MODULE } from "@/store/constants/store";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HomeView",
  components: { MessageWelcome, UserData },
  data() {
    return {
      routeCancel: CANCEL_PLAN_VIEW,
    };
  },

  computed: {
    ...mapGetters("store", ["paymentStatusUser"]),
    ...mapGetters({
      eventGtm: "eventGtm",
      getProfile: `${STORE_MODULE}/${STORE}`,
    }),
    isNotSubscriber() {
      return this.paymentStatusUser == "not_subscriber";
    },
  },

  watch: {
    "$store.state.store.store.lang": {
      handler() {
        this.initialize();
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      getStore: GET_STORE,
      setLanguage: CHANGE_LANGUAGE,
    }),
    async initialize() {
      switch (this.$store.state.store.store.lang) {
        case "pt":
          this.setLanguage("pt-BR");
          this.$i18n.locale = "pt-BR";
          break;
        case "es":
          this.setLanguage("es-ES");
          this.$i18n.locale = "es-ES";
          break;
        default:
          this.setLanguage("en-US");
          this.$i18n.locale = "en-US";
          break;
      }
    },
  },
};
</script>
<style scoped>
.home {
  @apply p-5 inline-flex w-full;
}
@media (max-width: 640px) {
  .home {
    @apply block;
  }
}
</style>
