import { render, staticRenderFns } from "./BoxMessageWelcome.vue?vue&type=template&id=1a1641e6&"
import script from "./BoxMessageWelcome.vue?vue&type=script&lang=js&"
export * from "./BoxMessageWelcome.vue?vue&type=script&lang=js&"
import style0 from "./BoxMessageWelcome.vue?vue&type=style&index=0&id=1a1641e6&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3944991734/src/minhaloja-v2-international/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1a1641e6')) {
      api.createRecord('1a1641e6', component.options)
    } else {
      api.reload('1a1641e6', component.options)
    }
    module.hot.accept("./BoxMessageWelcome.vue?vue&type=template&id=1a1641e6&", function () {
      api.rerender('1a1641e6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Home/BoxMessageWelcome.vue"
export default component.exports