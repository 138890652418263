<template>
  <div class="welcome-panel-right">
    <div
      v-if="isDesktop"
      class="inline-flex top-5 right-5 cursor-pointer opacity-25 hover:opacity-50 icon"
      @click="alertClose"
    >
      <div class="">
        <IconExit color="black" />
      </div>
    </div>
    <div class="h-full">
      <div>
        <div class="grid grid-cols-2 gap-x-6">
          <div class="mb-6 text-md font-extrabold">
            {{ $t("components.home.userData.title") }}
          </div>
        </div>

        <p class="item">
          <b>{{ $t("components.home.userData.account") }}</b>
          <code data-cy="dashboard-account-text">
            <span v-html="account" />
          </code>
        </p>
        <p class="item">
          <b>{{ $t("components.home.userData.plan") }}</b>
          <code data-cy="dashboard-plan-text">
            <span v-if="isSubscriberPaying || isTrial"
              >Premium
              <span v-if="isTrial">- Trial</span>
            </span>
            <span v-else>{{ $t("components.home.userData.free") }}</span>
          </code>
        </p>
        <p
          v-if="
            isTrial ||
            isNonDefaulter ||
            (hasBankSlipOpen && subscription?.subscription_expiration)
          "
          class="item"
        >
          <b>{{ $t("components.home.userData.validity") }}</b>
          <code data-cy="dashboard-expiration-text">
            <span v-html="expiredAt" />
          </code>
        </p>
        <p v-if="!isTrial && !isFree && paymentMethod" class="item">
          <b>{{ $t("components.home.userData.paymentMethod") }}</b>
          <code data-cy="dashboard-payment_method-text">
            <span v-html="paymentMethod" />
            /
            <span v-if="recurrence" v-html="recurrence" />
            <span v-else v-html="'&hellip;'" />
          </code>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import * as config from "../../constants";
import { mapGetters } from "vuex";
import dayjs from "dayjs-ext";
import { MIGRATION_PLAN_VIEW, PLAN_SELECTOR_VIEW } from "@/router/constants";
import IconExit from "@/components/Icons/IconExit.vue";

export default {
  data() {
    return {
      not_found: "—",
      recurrences: config.Recurrences,
      plans: config.Plans,
      paymentTypes: {
        1: this.$t("paymentTypes.1"),
        2: this.$t("paymentTypes.2"),
        7: this.$t("paymentTypes.7"),
        8: this.$t("paymentTypes.8"),
        11: this.$t("paymentTypes.11"),
        12: this.$t("paymentTypes.12"),
        13: this.$t("paymentTypes.13"),
        14: this.$t("paymentTypes.14"),
        15: this.$t("paymentTypes.15"),
        16: this.$t("paymentTypes.16"),
        17: this.$t("paymentTypes.17"),
        18: this.$t("paymentTypes.18"),
        19: this.$t("paymentTypes.19"),
        20: this.$t("paymentTypes.20"),
        21: this.$t("paymentTypes.21"),
        24: this.$t("paymentTypes.24"),
        25: this.$t("paymentTypes.25"),
        26: this.$t("paymentTypes.26"),
        27: this.$t("paymentTypes.27"),
        50: this.$t("paymentTypes.50"),
        100: this.$t("paymentTypes.100"),
        101: this.$t("paymentTypes.101"),
      },
    };
  },
  components: {
    IconExit,
  },
  computed: {
    ...mapGetters("store", [
      "paymentStatusUser",
      "isNonDefaulter",
      "store",
      "isTrial",
      "subscription",
    ]),
    ...mapGetters({
      isDesktop: `isDesktop`,
      eventGtm: "eventGtm",
    }),
    isAvailableToMigration() {
      return !this.isFree;
    },
    accessOrigin() {
      return this.$store.getters.accessOrigin;
    },
    isFree() {
      return (
        this.paymentStatusUser === "ex_subscriber" ||
        this.paymentStatusUser === "not_subscriber"
      );
    },
    isSubscriberPaying() {
      return (
        this.paymentStatusUser?.includes?.("paying") ||
        dayjs(this.subscription?.subscription_expiration).isAfter()
      );
    },
    hasBankSlipOpen() {
      return this.paymentStatusUser === "subscriber_bankslip_open";
    },
    hasPendingBill() {
      return (
        new Date(this.subscription?.last_bankslip_expiration_at) >= new Date()
      );
    },

    pendingBillRecurrence() {
      return this.plans[this.subscription?.license_type];
    },
    isExtended() {
      if (!this.subscription || !this.subscription?.subscription_expiration) {
        return false;
      }
      return !dayjs(this.subscription?.subscription_expiration).isBefore();
    },
    isPreLib() {
      return this.paymentStatusUser === "subscriber_bankslip_open_pre_lib";
    },
    account() {
      return this.store?.store?.account;
    },
    planservice() {
      return this.store?.priceId;
    },
    routeMigration() {
      const routeMigration =
        this.subscription?.is_pre_lib || this.subscription?.is_free
          ? PLAN_SELECTOR_VIEW
          : MIGRATION_PLAN_VIEW;
      return routeMigration;
    },
    expiredAt() {
      let expiration = this.subscription?.subscription_expiration;
      if (
        (this.isTrial && !this.subscription?.subscription_expiration) ||
        this.subscription?.subscription_expiration !=
          this.subscription?.license_expiration
      ) {
        expiration = this.subscription?.license_expiration;
      }
      return dayjs(expiration).format("DD/MM/YY");
    },
    recurrence() {
      let recurrence = null;
      if (!this.isFree) {
        recurrence =
          this.$t("recurrences_num." + this.subscription?.current_recurrence) ||
          "Sem recorrência";
      }
      return recurrence;
    },
    paymentMethod() {
      let paymentMethod = null;
      if (!this.isFree) {
        paymentMethod = this.paymentTypes[this.subscription?.payment_type];
      }
      return paymentMethod;
    },
  },
  methods: {
    alertClose() {
      alert("@@fechar");
    },
  },
};
</script>

<style lang="scss" scoped>
code {
  @apply block py-1 font-sans #{!important};
}

.welcome-panel-right {
  @apply w-1/3 border-l-2 pl-5 text-left;
}

.icon {
  margin-left: 100%;
}

.item {
  @apply mb-4;
}
.icon-item {
  @apply w-4 h-4 inline mr-2;
  color: teal;
  --fa-primary-opacity: 0.8;
  --fa-secondary-opacity: 0.2;
  --fa-secondary-color: #14c6df;
  --fa-primary-color: #14c6df;
}
.wrapper-feat {
  @apply h-full w-full flex items-center justify-center;
}
@media (max-width: 640px) {
  .welcome-panel-right {
    @apply w-full mt-5 border-t-2 border-l-0 pt-5;
  }
  .box-message-text {
    @apply p-0;
  }
}
</style>
